<template>
    <el-form ref="form" :model="form" label-width="140px" class="elForm" :rules="rules">
        <el-form-item label="选择商品：" prop="goods_id">
            <el-button type="text" @click="handleGoods">选择商品</el-button>
            <el-table :data="goodsInfo ? [goodsInfo] : []" :header-cell-style="{ 'background-color': '#F8F9FA' }"
                style="margin-top: 20px;width: 700px;" border>
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="create_time" label="商品" align="center">
                    <template v-slot="{ row }">
                        <div style="display: flex;align-items: center;">
                            <el-image :src="row.goods_cover_picture" alt=""
                                style="width: 60px;height: 60px;margin-right: 10px;flex-shrink: 0;"></el-image>
                            <div>{{ row.goods_name }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="goods_price" label="价格" align="center" width="200"> </el-table-column>
                <el-table-column prop="goods_stock" label="操作" align="center" width="200">
                    <template>
                        <el-button type="text" @click="delGoods">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-form-item>
        <el-form-item label="批发阶梯价格：" required>
            <div>请先设置购买价格</div>
            <el-table :data="form.ladder_price" :header-cell-style="{ 'background-color': '#F8F9FA' }"
                style="margin-top: 20px;width: 700px;">
                <el-table-column label="阶梯购买" header-align="center">
                    <template v-slot="{ row, $index }">
                        <div style="display: flex;align-items: center;">
                            <el-input type="text" v-model="row.start" style="width: 200px;" placeholder="请填写最低起批量"
                                v-if="$index == 0" @input="handleLadderPrice($event, $index, 'start')"></el-input>
                            <el-input type="text" v-model="row.start" style="width: 200px;" disabled v-else></el-input>
                            <template v-if="$index < 2">
                                <div style="margin:0 20px">至</div>
                                <el-input type="text" v-model="row.end" style="width: 200px;"
                                    @input="handleLadderPrice($event, $index, 'end')"></el-input>
                                <div style="margin-left: 20px;">份（不包括）</div>
                            </template>
                            <div v-else style="margin-left: 20px;">及以上（包括）</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-form-item>
        <el-form-item label="是否支持混批：" prop="is_mixedbatchh" required>
            <el-radio-group v-model="form.is_mixedbatchh">
                <el-radio :label="0">不支持</el-radio>
                <el-radio :label="1" :disabled="!goodsInfo.is_spec">支持</el-radio>
            </el-radio-group>
            <span class="tips">选择支持混批，则表示当用户购买多个规格时，全部规格的价格都按总数的阶梯价格计算，不支持混批，则按照每个规格数量对应的阶梯价格计算</span>
            <!-- 多规格 -->
            <el-table :data="goodsInfo.goods_spec" :header-cell-style="{ 'background-color': '#F8F9FA' }"
                style="margin-top: 20px;" v-if="form.is_mixedbatchh" border>
                <el-table-column align="center" v-for="(item, index) in goodsInfo.spec" :key="item.id"
                    :label="item.spec_name">
                    <template v-slot="{ row }">{{ row.sku[index].spec_value }}</template>
                </el-table-column>
                <el-table-column label="阶梯购买" align="center">
                    <template v-slot="{ $index }">
                        <div class="ladder">
                            <div v-for="(item, index) in form.spec[$index].ladder_price" :key="index" class="item">
                                {{ index == 2 ? item.start + '个以上（包括）' : item.start + '~' + item.end + '个（不包括）' }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="批发价格（元）" align="center">
                    <template v-slot="{ $index }">
                        <div class="ladder">
                            <div v-for="item in form.spec[$index].ladder_price" :key="item" class="item">
                                <el-input type="text" v-model="item.price" style="width: 150px;"></el-input>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="批发库存" align="center">
                    <template v-slot="{ $index }">
                        <el-input type="text" v-model="form.spec[$index].stock" style="width: 150px;"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 无规格 -->
            <el-table :data="form.ladder_price" :header-cell-style="{ 'background-color': '#F8F9FA' }"
                style="margin-top: 20px;width: 700px;" v-else :span-method="objectSpanMethod" border>
                <el-table-column label="阶梯购买" align="center">
                    <template v-slot="{ row, $index }">
                        {{ $index == 2 ? row.start + '个以上（包括）' : row.start + '~' + row.end + '个（不包括）' }}
                    </template>
                </el-table-column>
                <el-table-column label="批发价格（元）" align="center">
                    <template v-slot="{ row }">
                        <el-input type="text" v-model="row.price" style="width: 150px;"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="批发库存" align="center">
                    <template v-slot="{ $index }">
                        <el-input type="text" v-model="p_stock2[$index]" style="width: 150px;"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <el-form-item label="批量输入：" style="margin-top: 20px;">
                <el-input placeholder="价格（元）" v-model="p_price" style="width: 150px;margin-right: 10px;"></el-input>
                <el-input placeholder="库存" v-model="p_stock" style="width: 150px;margin-right: 10px;"></el-input>
                <el-button type="primary" @click="confirmPl">确定</el-button>
            </el-form-item>
        </el-form-item>
        <Preservation @preservation="onSubmit"></Preservation>

        <el-dialog title="选择可用商品" :visible.sync="dialogVisible" width="800px">
            <el-input type="text" v-model="g_form.goods_name" style="width: 300px;" placeholder="搜索商品名称"
                @keyup.enter.native="getGoodsList(1)">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="getGoodsList(1)"></i>
            </el-input>
            <!-- 列表 -->
            <el-table :data="g_list" :header-cell-style="{ 'background-color': '#F8F9FA' }" style="margin-top: 20px;">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="create_time" label="商品名称" align="center">
                    <template v-slot="{ row }">
                        <el-radio v-model="goods_id" :label="row.id">{{ row.goods_name
                        }}</el-radio>
                    </template>
                </el-table-column>
                <el-table-column prop="order_no" label="商品图片" align="center">
                    <template v-slot="{ row }">
                        <el-image :src="row.goods_cover_picture" alt="" style="width: 60px;height: 60px;"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="goods_price" label="商品价格" align="center"></el-table-column>
                <el-table-column prop="goods_stock" label="库存" align="center"></el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="g_total" :page="g_form.page" :pageNum="g_form.rows" @updatePageNum="updateData">
            </Paging>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmGoods">确 定</el-button>
            </span>
        </el-dialog>
    </el-form>
</template>

<script>
import Preservation from '@/components/preservation';
import Paging from '@/components/paging';
import { inspectPrice, inspectNonnegativeInteger } from '@/util/verification'
export default {
    components: {
        Preservation,
        Paging
    },
    data () {
        return {
            id: '',
            form: {
                goods_id: '',
                ladder_price: [{
                    start: '',
                    end: '',
                    price: ''
                }, {
                    start: '',
                    end: '',
                    price: ''
                }, {
                    start: '',
                    price: ''
                }],
                stock: '',
                is_mixedbatchh: 0,
                spec: ''
            },
            rules: {
                goods_id: [
                    { required: true, message: '请选择商品', trigger: 'change' },
                ],
            },
            goodsInfo: '',
            g_form: {
                page: 1,
                rows: 5,
                goods_name: ''
            },
            g_list: [],
            g_total: 0,
            dialogVisible: false,
            goods_id: '',
            p_price: '',
            p_stock: '',
            p_stock2: [],
            is_edit: false
        };
    },
    created () {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.is_edit = true
            this.getInfo()
        }
    },
    methods: {
        handleGoods () {
            this.g_form = {
                page: 1,
                rows: 5,
                goods_name: ''
            }
            this.dialogVisible = true
            this.getGoodsList()
        },
        getGoodsList (page = 0) {
            if (page) {
                this.g_form.page = 1
            }
            this.goods_id = ''
            this.$axios.post(this.$api.repair.marketing.purchaseGoodList, this.g_form).then(res => {
                if (res.code == 0) {
                    this.g_list = res.result.list;
                    this.g_total = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        updateData (val, status) {
            if (status == 0) {
                this.g_form.rows = val;
                this.getGoodsList();
            } else {
                this.g_form.page = val;
                this.getGoodsList();
            }
        },
        confirmGoods () {
            if (!this.goods_id) {
                return this.$message.warning('请选择商品');
            }
            this.form.goods_id = this.goods_id
            this.getGoodsInfo()
            this.dialogVisible = false

        },
        getGoodsInfo () {
            this.$axios.post('/Community' + this.$api.goods.goodsInfo, { id: this.goods_id }).then(res => {
                if (res.code == 0) {
                    this.goodsInfo = res.result
                    console.log(this.goodsInfo)
                    if (this.goodsInfo.is_spec) {
                        let arr = []
                        this.goodsInfo.goods_spec.forEach(item => {
                            arr.push({
                                sku_id: item.id,
                                stock: '',
                                ladder_price: [{
                                    start: '',
                                    end: '',
                                    price: ''
                                }, {
                                    start: '',
                                    end: '',
                                    price: ''
                                }, {
                                    start: '',
                                    price: ''
                                }]
                            })
                        })
                        this.form.spec = arr
                    }
                }
            })
        },
        delGoods () {
            this.$confirm('确定要删除此数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.form.goods_id = ''
                    this.goodsInfo = ''
                    this.goods_id = ''
                })
                .catch(() => { });
        },
        objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 2) {
                if (rowIndex === 0) {
                    return {
                        rowspan: 3,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
        handleLadderPrice (val, index, type) {
            if (type == 'end') {
                this.form.ladder_price[index * 1 + 1].start = val
            }
            if (this.goodsInfo.is_spec) {
                this.form.spec.forEach(item => {
                    item.ladder_price = [
                        {
                            start: this.form.ladder_price[0].start,
                            end: this.form.ladder_price[0].end,
                            price: ''
                        }, {
                            start: this.form.ladder_price[1].start,
                            end: this.form.ladder_price[1].end,
                            price: ''
                        }, {
                            start: this.form.ladder_price[2].start,
                            price: ''
                        }
                    ]
                })
            }
        },
        confirmPl () {
            if (this.form.is_mixedbatchh) {
                this.form.spec.forEach(item => {
                    item.stock = this.p_stock
                    item.ladder_price.forEach(item => {
                        item.price = this.p_price
                    })
                })
            } else {
                this.form.ladder_price.forEach(item => {
                    item.price = this.p_price
                })
                this.form.stock = this.p_stock
                this.$set(this.p_stock2, 0, this.p_stock)
            }
        },
        getInfo () {
            this.$axios.post(this.$api.repair.marketing.purchaseGoodInfo, {
                id: this.id
            }).then(res => {
                if (res.code == 0) {
                    let info = res.result
                    let spec = []
                    if (info.is_mixedbatchh) {
                        info.skulist.forEach(item => {
                            spec.push({
                                sku_id: item.sku_id,
                                stock: item.stock,
                                ladder_price: item.ladder_price_info,
                                wholesale_sku_id: item.id
                            })
                        })
                    }
                    this.form = {
                        id: info.id,
                        goods_id: info.goods_id,
                        ladder_price: info.ladder_price_info,
                        stock: info.stock,
                        is_mixedbatchh: info.is_mixedbatchh,
                        spec: spec.length ? spec : ''
                    }
                    this.goodsInfo = info.goods
                    this.p_stock2[0] = info.stock
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        onSubmit () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let form = { ...this.form }
                    if (form.is_mixedbatchh) {
                        if (form.spec.some(i => i.ladder_price.some(l => !l.start || !l.price))) {
                            return this.$message.warning('请将阶梯购买价格填写完整')
                        }
                        if (form.spec.some(i => i.ladder_price.some(l => !inspectNonnegativeInteger(l.start)))) {
                            return this.$message.warning('请填写阶梯购买数量正确格式')
                        }
                        if (form.spec.some(i => i.ladder_price.some(l => !inspectPrice(l.price)))) {
                            return this.$message.warning('请填写阶梯购买价格正确格式')
                        }
                        if (form.spec.some(i => !i.stock)) {
                            return this.$message.warning('请将批发库存填写完整')
                        }
                        if (form.spec.some(i => !inspectNonnegativeInteger(i.stock))) {
                            return this.$message.warning('请填写库存正确格式')
                        }

                        form.stock = form.spec.reduce((t, v) => {
                            return t + v.stock * 1
                        }, 0)

                    } else {
                        form.stock = this.p_stock2[0]
                        if (form.ladder_price.some(l => !l.start || !l.price)) {
                            return this.$message.warning('请将阶梯购买价格填写完整')
                        }
                        if (form.ladder_price.some(l => !inspectNonnegativeInteger(l.start))) {
                            return this.$message.warning('请填写阶梯购买数量正确格式')
                        }
                        if (form.ladder_price.some(l => !inspectPrice(l.price))) {
                            return this.$message.warning('请填写阶梯购买价格正确格式')
                        }
                        if (!form.stock) {
                            return this.$message.warning('请填写批发库存')
                        }
                        if (!inspectNonnegativeInteger(form.stock)) {
                            return this.$message.warning('请填写库存正确格式')
                        }
                    }
                    let url = this.is_edit ? this.$api.repair.marketing.purchaseGoodEdit : this.$api.repair.marketing.purchaseGoodAdd
                    this.$axios.post(url, form).then(res => {
                        if (res.code == 0) {
                            this.$message.success((this.is_edit ? '编辑' : '添加') + '成功');
                            this.$router.go(-1)
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.elForm {
    background-color: #fff;
    padding: 30px 30px 80px;
}

.centerBtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tips {
    color: #999;
    margin-left: 20px;
}

.ladder {
    height: 100%;

    .item {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        &+.item {
            border-top: 1px solid #EBEEF5;
        }
    }
}
</style>
